import React from 'react'
import { Provider } from './src/utils/mixpanel'
import mixpanel from 'mixpanel-browser'

if (process.env.NODE_ENV === 'development') {
  mixpanel.init('disable', { autotrack: false })
  mixpanel.disable()
} else {
  mixpanel.init('43646e76b749f9b61e4021a65b5e8c3a')
}

export const onRouteUpdate = ({ location }) => {
  if (process.env.NODE_ENV === `production`) {
    mixpanel.track('Web Pageview', location)

    if (typeof window.fbq === `function`) {
      window.fbq('track', 'PageView')
    }
  }
};

export const wrapRootElement = ({ element }) => (
  <Provider value={mixpanel}>
    { element }
  </Provider>
)
